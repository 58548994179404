<template>
  <div>
    <v-list-item>
      <v-list-item-content>{{ label }}</v-list-item-content>
      <v-list-item-content v-if="isEditable" class="align-content-end">
        <v-text-field
          outlined
          hide-details="auto"
          single-line
          :value="value"
          @change="(val) => $emit('update:value', isNumber ? Number(val) : val)"
          :type="isNumber ? 'number' : 'text'"
          @wheel="(e) => e.preventDefault()"
        >

        </v-text-field>
      </v-list-item-content>
      <v-list-item-content v-else class="align-content-end">{{ value }}</v-list-item-content>
    </v-list-item>
    <v-divider v-if="showDivider" />
  </div>
</template>

<script>
export default {
  name: "ProductACVItemDetails",
  props: {
    isEditable: { type: Boolean, default: false },
    showDivider: { type: Boolean, default: true },
    label: { type: String, default: "" },
    value: { type: Number|String, default: "" },
    isNumber: { type: Boolean, default: true }
  }
}
</script>

<style scoped>

</style>
